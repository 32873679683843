
import { Component, Vue } from "vue-property-decorator";
import CreateUserModal from "@/components/CreateUserModal.vue";
import IUser from "@/types/user";
import ManagementCard from "@/components/ManagementCard.vue";
import TheLoader from "@/components/TheLoader.vue";
import LessonPermissionsModal from "@/components/LessonPermissionsModal.vue";
import store from "@/store";


@Component({
  components: {
    LessonPermissionsModal,
    TheLoader,
    CreateUserModal,
    ManagementCard,
  },
})
export default class UserManagement extends Vue {
  private loading = true;

  async mounted(): Promise<void> {
    await this.$store.dispatch("users/getAllUsers");
    await this.$store.dispatch("users/getAllLessonPermissions");
    this.loading = false;
  }

  private get users(): IUser[] {
    return this.$store.getters["users/allUsers"];
  }

  private exportLink() {
    const { token } = store.getters
    fetch(`/api/exportUsersFile`, { headers: { "Token": token } })
      .then(res => {
        return res.blob()
      })
      .then(blob => {
        var file = window.URL.createObjectURL(blob);
        window.location.assign(file);
      });
  }
}
