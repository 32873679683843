import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"}),_c(VCardText,[_vm._v(_vm._s(_vm.text)+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Нет ")]),_c(VBtn,{attrs:{"text":"","loading":_vm.loading},on:{"click":function($event){return _vm.$emit('ok')}}},[_vm._v(" Да ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }