
import { Component, Vue, Ref } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import IUserCreate from "@/types/user-create";

@Component({
  components: {},
})
export default class CreateUserModal extends Vue {
  @Ref("form") form!: InstanceType<typeof ValidationObserver>;
  private loading = false;
  private errorMessage = "";

  private dialog = false;
  private firstName = "";
  private lastName = "";
  private middleName = "";
  private username = "";
  private password = "";
  private email = "";
  private permissions: ("manageCourses" | "manageUsers" | "readAnalytics")[] =
    [];
  private requiredRule = (val: string): boolean | string =>
    !!val || "Это поле обязательно для заполнения";

  private passwordRule = (val: string): boolean | string => {
    const regex = /^[a-zA-Z0-9]+$/;
    if (regex.test(val)) {
      return true;
    } else {
      return "Пароль не должен содержать спец.символы. (только цифыр)";
    }
  }

  private usernameRule = (val: string): boolean | string => {
    const regex = /^[a-zA-Z0-9]+$/;
    if (regex.test(val)) {
      return true;
    } else {
      return "Имя пользователя не должно содержать спец.символы.";
    }
  }

  private permissionsOptions = [
    { value: "manageUsers", text: "Управление пользователями" },
    { value: "readAnalytics", text: "Просмотр аналитики" },
    { value: "manageCourses", text: "Управление курсами" },
  ];

  private async submit(): Promise<void> {
    const isFormValid = this.validate();

    if (!isFormValid) {
      return;
    }

    const { firstName, lastName, middleName, username, password, email } = this;

    let userPermissions = {
      manageUsers: false,
      readAnalytics: false,
      manageCourses: false,
    };

    this.permissions.forEach((el) => {
      userPermissions[el] = true;
    });

    const userToCreate: IUserCreate = {
      fullName: [lastName, firstName, middleName]
        .filter((el) => !!el)
        .join(" "),
      username,
      password,
      email,
      ...userPermissions,
    };
    this.loading = true;

    const res = await this.$store.dispatch("users/createUser", userToCreate);

    if (res) {
      this.errorMessage = res;
    }

    this.loading = false;

    if (res === "") {
      this.closeModal();
    }
  }

  private closeModal() {
    this.dialog = false;
    this.firstName = "";
    this.lastName = "";
    this.middleName = "";
    this.username = "";
    this.password = "";
    this.email = "";
    this.permissions = [];
    this.form.reset();
    this.errorMessage = "";
  }

  private validate() {
    return this.form.validate();
  }
}
