
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import IUser from "@/types/user";
import IUserPermission from "@/types/user-permissions";
import ConfirmModal from "@/components/ConfirmModal.vue";
import ChangePasswordModal from "@/components/ChangePasswordModal.vue";
import IEditUserPermissions from "@/types/edit-user-permissions";
import { deleteUser } from "@/api/api";
import LessonPermissionsModal from "@/components/LessonPermissionsModal.vue";

@Component({
  components: { LessonPermissionsModal, ChangePasswordModal, ConfirmModal },
})
export default class ManagementCard extends Vue {
  @Prop({ required: true }) readonly user!: IUser;
  private deleteLessonLoading = false;
  private loading = false;
  private defaultUserPermissions: IUserPermission = {
    manageCourses: false,
    manageUsers: false,
    readAnalytics: false,
  };

  private userPermissions = { ...this.defaultUserPermissions };

  private showPermissionsConfirmModal = false;
  private showDeactivateConfirmModal = false;

  private get currentUserPermissions(): IUserPermission {
    const { manageCourses, manageUsers, readAnalytics } = this.user;
    return {
      manageCourses,
      manageUsers,
      readAnalytics,
    };
  }

  get formChanged(): boolean {
    const { manageCourses, manageUsers, readAnalytics } = this.user;
    const {
      manageCourses: manageCoursesNew,
      manageUsers: manageUsersNew,
      readAnalytics: readAnalyticsNew,
    } = this.userPermissions;

    return (
      manageCourses !== manageCoursesNew ||
      manageUsers !== manageUsersNew ||
      readAnalytics !== readAnalyticsNew
    );
  }

  @Watch("user", { immediate: true })
  private updateUserPermissions() {
    const { manageCourses, manageUsers, readAnalytics } = this.user;

    this.userPermissions = {
      manageCourses,
      manageUsers,
      readAnalytics,
    };
  }

  private async setUserPermissions(): Promise<void> {
    const data: IEditUserPermissions = {
      userID: this.user.id,
      ...this.userPermissions,
    };

    const res = await this.$store.dispatch("users/editPermissions", data);

    if (res) {
      this.showPermissionsConfirmModal = false;
    }
  }

  private async deactivateUser() {
    this.deleteLessonLoading = true;

    try {
      await deleteUser(this.user.id);
    } catch (e) {
      console.error(e);
    } finally {
      this.deleteLessonLoading = false;
    }
    this.showDeactivateConfirmModal = false;
  }

  private cancelUserPermissions() {
    this.userPermissions = this.currentUserPermissions;
    this.showPermissionsConfirmModal = false;
  }

  private get isUserActive() {
    return this.user.loginAllowed;
  }
}
