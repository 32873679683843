
import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import IChangePassword from "@/types/change-password";

@Component({
  components: {},
})
export default class ChangePasswordModal extends Vue {
  @Ref("form") form!: InstanceType<typeof ValidationObserver>;
  @Prop({ required: true }) id!: number;

  private dialog = false;
  private password = "";

  private requiredRule = (val: string): boolean | string =>
    !!val || "Это поле обязательно для заполнения";

  private async submit(): Promise<void> {
    const isFormValid = this.validate();

    if (!isFormValid) {
      return;
    }
    const { id, password } = this;

    const passwordData: IChangePassword = {
      password,
      userID: id,
    };
    const res = await this.$store.dispatch(
      "users/changePassword",
      passwordData
    );

    if (res) {
      this.closeModal();
    }
  }

  private closeModal() {
    this.password = "";
    this.form.reset();
    this.dialog = false;
  }

  private validate() {
    return this.form.validate();
  }
}
