import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"user-management"},[_c('h1',[_vm._v("Управление пользователями")]),(_vm.loading)?_c('the-loader'):_vm._e(),_c(VScrollYTransition,{attrs:{"group":""}},[(_vm.users && _vm.users.length > 0)?_vm._l((_vm.users),function(user){return _c('management-card',{key:user.id,staticClass:"user-management__card",attrs:{"user":user}})}):_vm._e()],2),(!_vm.loading && !(_vm.users && _vm.users.length > 0))?_c('p',[_vm._v(" Пользователей пока нет. Создать? ")]):_vm._e(),_c(VRow,[_c(VCol,[_c('create-user-modal')],1),_c(VCol,[_c(VBtn,{on:{"click":_vm.exportLink}},[_vm._v("Экспорт пользователей")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }