
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import IUser from "@/types/user";
import {
  IAllLessonsPermissions,
  ILessonPermissionInfo,
} from "@/types/lesson-permissions";
import { flatten } from "lodash";

@Component({
  components: {},
})
export default class LessonPermissionsModal extends Vue {
  @Prop({ required: true }) private readonly user!: IUser;
  private dialog = false;
  private error = "";
  private loading = false;

  private get userPermissions() {
    return this.user.lessonPermissions;
  }

  private get allLessonsPermissions() {
    return this.$store.getters["users/lessonPermissions"].filter(
      (el: IAllLessonsPermissions) => !!el.lessons.length
    );
  }

  private data: { [key: number]: boolean } = {};

  @Watch("allLessonsPermissions")
  private onAllLessonChange(): void {
    const lessonIds: ILessonPermissionInfo[] = flatten(
      this.allLessonsPermissions.map((el: IAllLessonsPermissions) => el.lessons)
    );

    lessonIds.forEach((el: ILessonPermissionInfo) => {
      this.data[el.id] = this.userPermissions.some((p) => p === el.id);
    });
  }

  private onClose(): void {
    this.dialog = false;
    this.error = "";
  }

  private async onSuccess(): Promise<void> {
    const lessonPermissions = Object.keys(this.data)
      .filter((el: any) => !!this.data[el])
      .map((el) => +el);
    this.loading = true;
    const error = await this.$store.dispatch(
      "users/updateUserLessonPermissions",
      {
        userID: this.user.id,
        lessonPermissions,
      }
    );
    this.error = error;
    this.loading = false;

    if (!error) {
      this.onClose();
    }
  }
}
