import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Доступ к урокам ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"form"},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Доступ к урокам")])]),_c(VCardText,_vm._l((_vm.allLessonsPermissions),function(p,idx){return _c('div',{key:idx},[_c('p',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(p.name)+" "),_vm._l((p.lessons),function(l,idx){return _c(VCheckbox,{key:idx,staticClass:"ml-6",attrs:{"label":l.name},model:{value:(_vm.data[l.id]),callback:function ($$v) {_vm.$set(_vm.data, l.id, $$v)},expression:"data[l.id]"}})})],2)])}),0),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onClose}},[_vm._v(" Отмена ")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onSuccess}},[_vm._v(" Обновить ")])],1),_c(VExpandTransition,[(_vm.error)?_c(VCardText,{staticClass:"base-error"},[_vm._v(" Неверный логин или пароль ")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }