
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ConfirmModal extends Vue {
  @Prop({ required: true }) private readonly dialog!: boolean;
  @Prop({ required: true }) private readonly text!: string;
  @Prop({ default: false }) private readonly loading!: boolean;
}
