import { render, staticRenderFns } from "./LessonPermissionsModal.vue?vue&type=template&id=16e9af69&scoped=true&"
import script from "./LessonPermissionsModal.vue?vue&type=script&lang=ts&"
export * from "./LessonPermissionsModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16e9af69",
  null
  
)

export default component.exports